import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Link as LinkThemed } from "theme-ui"

const LinePost = ({ titulo, data, url }) => {

  return (
    <section>
        <LinkThemed variant="secondary" as={Link} to={"/" + url}>
            {titulo}
            <small> - {data}</small>
        </LinkThemed>
    </section>
  )
}

LinePost.propTypes = {
    titulo: PropTypes.string,
    data: PropTypes.string,
    url: PropTypes.string
}

LinePost.defaultProps = {
    titulo: "",
    data: "",
    url: "",
}

export default LinePost
